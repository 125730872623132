.Card {
  width: 7vw;
  height: 60px;
  border-radius: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;

  font-weight: bold;
  color: #333;
  line-height: 1;
  font-size: 30px;
}

.Card-♥ {
  color: rgb(199, 5, 5);
}

.Card-♦ {
  color: rgb(221, 112, 10);
}

.Card-♣ {
  color: rgb(3, 141, 22);
}

.Card-♠ {
  color: rgb(5, 43, 167);
}
