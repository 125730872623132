.Login {
  width: 80%;
  padding: 10px;
  margin: 100px auto;
  text-align: center;
}

.Login label,
.Login input,
.Login button {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  font-size: 25px;
  margin-left: auto;
  margin-right: auto;
}

.Login label {
  font-weight: bold;
}

.Login input {
  border-radius: 5px;
  background: white;
  border: none;
  padding: 10px;
  width: calc(100% - 20px);
}

.Login button {
  padding: 10px;
  color: white;
  background: rgb(0, 212, 0);
  border-radius: 5px;
  border: none;
  box-shadow: -2px -2px 0px inset rgba(0, 0, 0, 0.2);
}
