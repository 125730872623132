.SeatPicker__Page {
  text-align: center;
}

.SeatPicker {
  width: 90vw;
  height: 30vh;
  margin: 0 5vw;
  position: relative;
  margin-top: 100px;
}

.SeatPicker button {
  padding: 10px;
  color: white;
  background: rgb(0, 212, 0);
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  border: none;
  box-shadow: -2px -2px 0px inset rgba(0, 0, 0, 0.2);
}

.SeatPicker button:disabled {
  opacity: 0.5;
  background: rgba(122, 122, 122);
}

.SeatPicker__Seat--N {
  top: 0;
  left: 27.5%;
  width: 45%;
  position: absolute;
}

.SeatPicker__Seat--S {
  bottom: 0;
  left: 27.5%;
  width: 45%;
  position: absolute;
}

.SeatPicker__Seat--W {
  top: calc(50% - 20px);
  left: 0;
  width: 45%;
  position: absolute;
}

.SeatPicker__Seat--E {
  top: calc(50% - 20px);
  right: 0;
  width: 45%;
  position: absolute;
}
