.AdminAction {
  padding: 10px;
  color: white;
  background: rgb(122, 122, 122);
  border-radius: 5px;
  border: none;
  box-shadow: -2px -2px 0px inset rgba(0, 0, 0, 0.2);
  font-size: 20px;
}

.AdminList {
  margin: 0 auto;
  padding: 0;
  width: 90%;
  font-size: 20px;
  list-style-type: none;
  padding-top: 100px;
}

.AdminList li {
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
}

.AdminList li button {
  width: 100%;
}

.AdminList .AdminMultiButton button {
  width: auto;
  display: inline-block;
  margin-left: 5px;
}
