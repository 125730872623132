.AssignWinner {
  position: absolute;
  z-index: 10;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30vh;
}

.AssignWinner h1 {
  text-align: center;
}

.AssignWinner button {
  padding: 10px;
  color: white;
  background: rgb(0, 212, 0);
  border-radius: 5px;
  border: none;
  box-shadow: -2px -2px 0px inset rgba(0, 0, 0, 0.2);
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}
